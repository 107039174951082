<template> 
  <div class="book-piece-by-piece-page">
    <!-- Header with back button -->
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />

    <!-- Main scrollable container -->
    <main class="container" ref="container" role="main">
      <section class="content" aria-label="Read Book by Paragraph">
        <h1 class="book-title">{{ book.title }}</h1>
        <div class="paragraph-container">
          <p class="paragraph-text">{{ currentParagraph }}</p>
        </div>
        <!-- Instructions updated for clarity on both desktop & mobile -->
        <div class="instructions">
          <p>
            <strong>Desktop:</strong> Use <strong>Space</strong> or <strong>Right Arrow</strong> to advance, and <strong>Left Arrow</strong> to go back.
          </p>
          <p>
            <strong>Mobile:</strong> Tap anywhere to advance, swipe <strong>right</strong> to advance, and swipe <strong>left</strong> to go back.
          </p>
        </div>
        <div class="navigation">
          <button
            v-if="currentIndex > 0"
            @click="previousParagraph"
            class="nav-button prev-button"
            aria-label="Previous Paragraph"
          >
            ← Previous
          </button>
          <button
            v-if="currentIndex < paragraphs.length - 1"
            @click="nextParagraph"
            class="nav-button next-button"
            aria-label="Next Paragraph"
          >
            Next →
          </button>
          <p v-else class="end-message">End of Book</p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";
import axios from "@/axiosConfig.js";
import books from "@/books.js"; // Import shared books list

export default {
  name: "BookPieceByPiece",
  components: { Header },
  data() {
    return {
      book: { id: null, title: "", txt: "" },
      bookContent: "",
      paragraphs: [],
      currentIndex: 0,
      // Mobile touch event properties:
      touchStartX: 0,
      touchStartY: 0,
      touchMoved: false,
      swipeHandled: false,
    };
  },
  computed: {
    currentParagraph() {
      return this.paragraphs[this.currentIndex] || "";
    },
  },
  async mounted() {
    const bookId = parseInt(this.$route.params.bookId, 10);
    this.book = books.find((b) => b.id === bookId) || { id: null, title: "", txt: "" };
    await this.fetchBookContent();
    this.splitTextIntoParagraphs();
    const savedIndex = localStorage.getItem(`progress_${this.book.id}`);
    if (savedIndex !== null) {
      this.currentIndex = parseInt(savedIndex, 10);
    }
    this.restoreScrollPosition();
    window.addEventListener("keydown", this.handleKeyDown);
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
      // Mobile event listeners:
      this.$refs.container.addEventListener("click", this.handleClick);
      this.$refs.container.addEventListener("touchstart", this.handleTouchStart, { passive: true });
      this.$refs.container.addEventListener("touchmove", this.handleTouchMove, { passive: true });
      this.$refs.container.addEventListener("touchend", this.handleTouchEnd, { passive: true });
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
      // Remove mobile event listeners:
      this.$refs.container.removeEventListener("click", this.handleClick);
      this.$refs.container.removeEventListener("touchstart", this.handleTouchStart);
      this.$refs.container.removeEventListener("touchmove", this.handleTouchMove);
      this.$refs.container.removeEventListener("touchend", this.handleTouchEnd);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
  methods: {
    async fetchBookContent() {
      if (!this.book.txt) return;
      try {
        const response = await axios.get(this.book.txt, { responseType: "text" });
        this.bookContent = response.data;
      } catch (error) {
        console.error("Failed to fetch book text:", error);
        this.bookContent = "(Failed to load the text.)";
      }
    },
    splitTextIntoParagraphs() {
      this.paragraphs = this.bookContent
        .split(/\n+/)
        .map((p) => p.trim())
        .filter((p) => p.length > 0);
      if (this.currentIndex >= this.paragraphs.length) {
        this.currentIndex = 0;
      }
    },
    nextParagraph() {
      if (this.currentIndex < this.paragraphs.length - 1) {
        this.currentIndex++;
        this.saveProgress();
      }
    },
    previousParagraph() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.saveProgress();
      }
    },
    handleKeyDown(event) {
      if (event.code === "ArrowRight" || event.code === "Space") {
        event.preventDefault();
        this.nextParagraph();
      } else if (event.code === "ArrowLeft") {
        event.preventDefault();
        this.previousParagraph();
      }
    },
    // Mobile touch event handlers:
    handleTouchStart(e) {
      this.touchStartX = e.changedTouches[0].clientX;
      this.touchStartY = e.changedTouches[0].clientY;
      this.touchMoved = false;
    },
    handleTouchMove(e) {
      const deltaX = e.changedTouches[0].clientX - this.touchStartX;
      if (Math.abs(deltaX) > 10) {
        this.touchMoved = true;
      }
    },
    handleTouchEnd(e) {
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;
      const deltaX = touchEndX - this.touchStartX;
      const deltaY = touchEndY - this.touchStartY;
      // Only consider it a swipe if horizontal movement is significant and vertical movement is small
      if (this.touchMoved && Math.abs(deltaX) > 50 && Math.abs(deltaY) < 50) {
        if (deltaX > 0) {
          // Swipe right → next paragraph
          this.nextParagraph();
        } else {
          // Swipe left → previous paragraph
          this.previousParagraph();
        }
        this.swipeHandled = true;
      }
    },
    handleClick(e) {
      // Prevent a tap from also firing if a swipe was just handled.
      if (this.swipeHandled) {
        this.swipeHandled = false;
        return;
      }
      this.nextParagraph();
    },
    saveProgress() {
      if (this.book.id) {
        localStorage.setItem(`progress_${this.book.id}`, this.currentIndex);
      }
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem(
          `scrollPosition_Book_${this.book.id}`,
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const savedPosition = localStorage.getItem(`scrollPosition_Book_${this.book.id}`);
      this.$nextTick(() => {
        if (this.$refs.container && savedPosition) {
          this.$refs.container.scrollTop = parseInt(savedPosition, 10);
        }
      });
    },
    goBackToChooseAction() {
      this.saveProgress();
      this.saveScrollPosition();
      this.$router.push({
        name: "choose-action",
        params: { topicType: "book", topicId: this.book.id, topicName: this.book.title },
      });
    },
  },
};
</script>

<!-- Scoped Styles for BookPieceByPiece.vue -->
<style scoped>
.book-piece-by-piece-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.container {
  margin-top: 80px;
  /* Use min-height so container can extend beyond viewport if content is long */
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background: #f7f7f7;
  font-family: "Helvetica Neue", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* Extra bottom padding ensures all text is visible */
  padding-bottom: 120px;
}
.content {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.book-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Georgia", serif;
  color: #333;
  font-weight: 600;
}
.paragraph-container {
  width: 100%;
  max-width: 800px;
  background: #fafafa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.paragraph-text {
  font-size: 1.2rem;
  line-height: 1.6;
  font-family: "Georgia", serif;
  text-align: justify;
  color: #444;
}
.instructions {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  color: #666;
}
.navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.nav-button {
  background-color: #2c2c2c;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}
.nav-button:hover,
.nav-button:focus {
  background-color: #444;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  outline: none;
}
.end-message {
  font-size: 1rem;
  color: #555;
}
@media (max-width: 600px) {
  .container {
    padding: 10px;
    padding-bottom: 100px; /* Increase bottom padding on mobile if needed */
  }
  .content {
    padding: 15px;
  }
  .book-title {
    font-size: 1.6rem;
  }
  .paragraph-text {
    font-size: 1rem;
  }
  .nav-button {
    padding: 8px 16px;
    font-size: 14px;
  }
  .instructions {
    font-size: 0.9rem;
  }
}
</style>

<!-- Global Scrollbar Styles (Optional) -->
<style>
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>
