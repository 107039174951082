<template>
  <div class="book-details-page">
    <!-- Header with back button -->
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />
    <!-- Main scrollable container -->
    <main class="container" ref="container" role="main">
      <section class="content" role="document" aria-label="Book Content">
        <h1 class="centered-title">{{ book.title }}</h1>
        <!-- Display the book text in a preformatted block -->
        <pre class="centered-content">{{ bookContent }}</pre>
      </section>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";
import axios from "@/axiosConfig.js";
import books from "@/books.js"; // Import shared books list

export default {
  name: "BookDetails",
  components: { Header },
  data() {
    return {
      book: { id: null, title: "", txt: "" },
      bookContent: "",
    };
  },
  mounted() {
    const bookId = parseInt(this.$route.params.bookId, 10);
    this.book = books.find((b) => b.id === bookId) || { id: null, title: "", txt: "" };
    this.fetchBookContent().then(() => {
      this.restoreScrollPosition();
    });
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    next();
  },
  methods: {
    async fetchBookContent() {
      if (!this.book.txt) return;
      try {
        const response = await axios.get(this.book.txt, { responseType: "text" });
        this.bookContent = response.data;
      } catch (error) {
        console.error("Failed to fetch book text:", error);
        this.bookContent =
          "(Could not load the text. Please check S3 public policy and CORS settings.)";
      }
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem(
          `scrollPosition_Book_${this.book.id}`,
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const savedPosition = localStorage.getItem(`scrollPosition_Book_${this.book.id}`);
      this.$nextTick(() => {
        if (this.$refs.container && savedPosition) {
          this.$refs.container.scrollTop = parseInt(savedPosition, 10);
        }
      });
    },
    goBackToChooseAction() {
      this.saveScrollPosition();
      this.$router.push({
        name: "choose-action",
        params: { topicType: "book", topicId: this.book.id, topicName: this.book.title },
      });
    },
  },
};
</script>

<!-- Global Scrollbar Styles (Optional) -->
<style>
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>

<!-- Scoped Styles for BookDetails.vue -->
<style scoped>
.book-details-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Updated container: prevents horizontal scrolling/movement */
.container {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden; /* Disables horizontal scrolling */
  box-sizing: border-box;
  background: linear-gradient(135deg, #ffffff, #f3f3f3);
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 120px; /* Extra bottom padding */
  touch-action: pan-y;
  overscroll-behavior: contain;
}

.content {
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  line-height: 1.2;
  font-family: "Georgia", serif;
  color: #333;
  font-weight: 600;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  line-height: 1.6;
  font-family: "Georgia", serif;
  color: #444;
  width: 100%;
  max-width: 800px;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .container {
    padding: 10px;
    padding-bottom: 100px;
    overflow-x: hidden;
    touch-action: pan-y;
    overscroll-behavior: contain;
  }
  .content {
    margin: 10px;
    padding: 10px;
  }
  .centered-title {
    font-size: 1.4rem;
  }
  .centered-content {
    font-size: 1rem;
  }
}
</style>
