<template>
  <div class="donate-page">
    <!-- Header with back button -->
    <Header
      :showBackButton="true"
      backText="Previous Page"
      @back="goBackToPreviousPage"
    />

    <!-- Main donation content -->
    <main class="donate-content">
      <h1>Donate If You Want</h1>
      <p class="donation-subtitle">
        Your support helps us continue providing valuable resources. 
        Any amount is greatly appreciated!
      </p>

      <div class="payment-options">
        <button
          @click="donateWithStripe"
          class="stripe-button"
          aria-label="Donate with Stripe"
        >
          Donate with Stripe
        </button>
      </div>

      <!-- Error message with a fade transition -->
      <transition name="fade">
        <p v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </p>
      </transition>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "DonatePage",
  components: { Header },
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
    donateWithStripe() {
      try {
        // Redirect to the Stripe donation page
        window.location.href = "https://donate.stripe.com/14k5mt0zk7DXf1m9AA";
      } catch (error) {
        // Display an error message if the process fails
        this.errorMessage =
          "There was an error with the donation process. Please try again.";
      }
    },
    goBackToPreviousPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
/* 
 * Overall Page Container 
 */
.donate-page {
  /* The actual scrolling is handled by #app; 
     this container just holds the header + content. */
}

/* 
 * Main Donation Content 
 */
.donate-content {
  margin-top: 80px; /* Adjust if your header height changes */
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;

  /* Subtle background gradient with slight transparency */
  background: linear-gradient(
    135deg,
    rgba(249, 249, 249, 0.9),
    rgba(243, 243, 243, 0.9)
  );
  backdrop-filter: blur(4px);
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/*
 * Heading & Subtitle
 */
h1 {
  margin-bottom: 10px;
  font-size: 2rem;
  color: #333;
  font-weight: 700;
}

.donation-subtitle {
  font-size: 1rem;
  color: #555;
  margin-bottom: 30px;
  max-width: 500px;
}

/* 
 * Payment Options Container 
 */
.payment-options {
  margin-top: 10px;
}

/* 
 * Stripe Donation Button 
 */
.stripe-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 14px 20px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  font-weight: 500;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  outline: none;
}
.stripe-button:hover,
.stripe-button:focus {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ac4dac, #ff4747);
}

/* 
 * Error message styling 
 */
.error-message {
  color: #d9534f;
  margin-top: 15px;
  font-weight: 500;
  font-size: 1rem;
}

/* 
 * Fade transition for error messages 
 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
