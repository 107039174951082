<template>
  <div class="simpler-book-piece-by-piece-page">
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />

    <main class="container" ref="container" role="main">
      <!-- Content display -->
      <section v-if="!isLoading && !errorMessage" class="content">
        <h1 class="book-title">{{ book.title }}</h1>
        <div class="paragraph-container">
          <p class="paragraph-text">{{ currentParagraph }}</p>
        </div>
        <!-- Clear instructions for desktop and mobile users -->
        <div class="instructions">
          <p>
            <strong>Desktop:</strong> Use <strong>Space</strong> or <strong>Right Arrow</strong> to advance, and <strong>Left Arrow</strong> to go back.
          </p>
          <p>
            <strong>Mobile:</strong> Tap anywhere to advance, swipe <strong>right</strong> to advance, and swipe <strong>left</strong> to go back.
          </p>
        </div>
        <div class="navigation">
          <button
            v-if="currentIndex > 0"
            @click="previousParagraph"
            class="nav-button prev-button"
            aria-label="Previous Paragraph"
          >
            ← Previous
          </button>
          <button
            v-if="currentIndex < paragraphs.length - 1"
            @click="nextParagraph"
            class="nav-button next-button"
            aria-label="Next Paragraph"
          >
            Next →
          </button>
          <p v-else class="end-message">End of Book</p>
        </div>
      </section>
      <!-- Loading state -->
      <section v-else-if="isLoading" class="loading-container">
        <p class="loading-message">Loading book details…</p>
      </section>
      <!-- Error state -->
      <section v-else class="error-container">
        <p class="error-message">{{ errorMessage }}</p>
      </section>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";
import axios from "@/axiosConfig.js";

export default {
  name: "SimplerBookPieceByPiece",
  components: { Header },
  data() {
    return {
      book: { id: null, title: "", txt: "", price: 0 },
      bookContent: "",
      paragraphs: [],
      currentIndex: 0,
      errorMessage: "",
      isLoading: true,
      sessionId: null,
      paid: false,
      // Mobile touch event properties:
      touchStartX: 0,
      touchStartY: 0,
      touchMoved: false,
      swipeHandled: false,
    };
  },
  computed: {
    currentParagraph() {
      return this.paragraphs[this.currentIndex] || "";
    },
  },
  async created() {
    const bookId = this.$route.params.bookId;
    try {
      // Fetch book details from the API
      const response = await axios.get(`/api/stripe/book-details/${bookId}`);
      this.book = response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        this.errorMessage =
          "Book not found. Please contact resuvonia@gmail.com for assistance.";
      } else {
        this.errorMessage = "Failed to load book details. Please try again.";
      }
      this.isLoading = false;
      return;
    }
    this.isLoading = false;

    // Load cached book content if available, otherwise fetch it
    if (this.book && localStorage.getItem(`simplerBookContent_${this.book.id}`)) {
      this.bookContent = localStorage.getItem(`simplerBookContent_${this.book.id}`);
      this.paid = true;
    } else if (this.book.txt) {
      await this.fetchBookContent();
    }

    this.splitTextIntoParagraphs();

    // Restore saved progress if available
    const savedIndex = localStorage.getItem(`progress_${this.book.id}`);
    if (savedIndex !== null) {
      this.currentIndex = parseInt(savedIndex, 10);
    }

    this.$nextTick(() => {
      this.restoreScrollPosition();
    });

    window.addEventListener("keydown", this.handleKeyDown);
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
      // Mobile event listeners:
      this.$refs.container.addEventListener("click", this.handleClick);
      this.$refs.container.addEventListener("touchstart", this.handleTouchStart, { passive: true });
      this.$refs.container.addEventListener("touchmove", this.handleTouchMove, { passive: true });
      this.$refs.container.addEventListener("touchend", this.handleTouchEnd, { passive: true });
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
      // Remove mobile event listeners:
      this.$refs.container.removeEventListener("click", this.handleClick);
      this.$refs.container.removeEventListener("touchstart", this.handleTouchStart);
      this.$refs.container.removeEventListener("touchmove", this.handleTouchMove);
      this.$refs.container.removeEventListener("touchend", this.handleTouchEnd);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
  methods: {
    async fetchBookContent() {
      if (!this.book.txt) return;
      try {
        const response = await axios.get(this.book.txt, { responseType: "text" });
        this.bookContent = response.data;
        localStorage.setItem(`simplerBookContent_${this.book.id}`, response.data);
      } catch (error) {
        console.error("Failed to fetch book text:", error);
        this.bookContent = "(Failed to load book text.)";
      }
    },
    splitTextIntoParagraphs() {
      if (!this.bookContent) return;
      this.paragraphs = this.bookContent
        .split(/\n+/)
        .map((p) => p.trim())
        .filter((p) => p.length > 0);
      if (this.currentIndex >= this.paragraphs.length) {
        this.currentIndex = 0;
      }
    },
    nextParagraph() {
      if (this.currentIndex < this.paragraphs.length - 1) {
        this.currentIndex++;
        this.saveProgress();
      }
    },
    previousParagraph() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.saveProgress();
      }
    },
    handleKeyDown(event) {
      if (event.code === "ArrowRight" || event.code === "Space") {
        event.preventDefault();
        this.nextParagraph();
      } else if (event.code === "ArrowLeft") {
        event.preventDefault();
        this.previousParagraph();
      }
    },
    // Mobile touch event handlers:
    handleTouchStart(e) {
      this.touchStartX = e.changedTouches[0].clientX;
      this.touchStartY = e.changedTouches[0].clientY;
      this.touchMoved = false;
    },
    handleTouchMove(e) {
      const deltaX = e.changedTouches[0].clientX - this.touchStartX;
      if (Math.abs(deltaX) > 10) {
        this.touchMoved = true;
      }
    },
    handleTouchEnd(e) {
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;
      const deltaX = touchEndX - this.touchStartX;
      const deltaY = touchEndY - this.touchStartY;
      if (this.touchMoved && Math.abs(deltaX) > 50 && Math.abs(deltaY) < 50) {
        if (deltaX > 0) {
          // Swipe right → next paragraph
          this.nextParagraph();
        } else {
          // Swipe left → previous paragraph
          this.previousParagraph();
        }
        this.swipeHandled = true;
      }
    },
    handleClick(e) {
      if (this.swipeHandled) {
        this.swipeHandled = false;
        return;
      }
      this.nextParagraph();
    },
    saveProgress() {
      if (this.book.id) {
        localStorage.setItem(`progress_${this.book.id}`, this.currentIndex);
      }
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem(
          `scrollPosition_Book_${this.book.id}`,
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const savedPosition = localStorage.getItem(`scrollPosition_Book_${this.book.id}`);
      this.$nextTick(() => {
        if (this.$refs.container && savedPosition) {
          this.$refs.container.scrollTop = parseInt(savedPosition, 10);
        }
      });
    },
    goBackToChooseAction() {
      this.saveProgress();
      this.saveScrollPosition();
      const topicId = this.book.id || this.$route.params.bookId;
      const topicName = this.book.title || "Unknown Book";
      if (!topicId) {
        this.$router.push({ name: "books" });
        return;
      }
      this.$router.push({
        name: "simpler-choose-action",
        params: {
          topicType: "book",
          topicId: topicId,
          topicName: topicName,
        },
      });
    },
  },
};
</script>

<!-- Scoped Styles for SimplerBookPieceByPiece.vue -->
<style scoped>
.simpler-book-piece-by-piece-page {
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Updated container style: use min-height and extra bottom padding */
.container {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  /* Use min-height so container grows if content exceeds viewport */
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  background: linear-gradient(135deg, #ffffff, #f3f3f3);
  padding: 20px;
  padding-bottom: 120px; /* Extra bottom padding for full content visibility */
}

/* Content block styling */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Book title */
.book-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-family: "Georgia", serif;
  font-weight: 600;
}

/* Paragraph container */
.paragraph-container {
  max-width: 800px;
  width: 100%;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Paragraph text */
.paragraph-text {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify;
  color: #444;
}

/* Instructions */
.instructions {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  color: #666;
}

/* Navigation buttons and end message */
.navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.nav-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
}

.nav-button:hover {
  background: linear-gradient(45deg, #ac4dac, #ff4747);
  transform: translateY(-2px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2);
}

.end-message {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .container {
    padding: 10px;
    padding-bottom: 100px; /* Extra bottom padding on mobile */
  }
  .book-title {
    font-size: 1.5rem;
  }
  .paragraph-text {
    font-size: 1rem;
  }
  .nav-button {
    padding: 8px 16px;
    font-size: 14px;
  }
  .instructions {
    font-size: 0.9rem;
  }
}
</style>

<!-- Global Scrollbar Styles (Optional) -->
<style>
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>
