import { createRouter, createWebHistory } from 'vue-router';

import BooksPage from '@/components/BooksPage.vue';
import BookDetails from '@/components/BookDetails.vue';
import SimplerBookDetails from '@/components/SimplerBookDetails.vue';
import SimplerBookPurchase from '@/components/SimplerBookPurchase.vue';
import ChooseActionPage from '@/components/ChooseActionPage.vue';
import SimplerChooseActionPage from '@/components/SimplerChooseActionPage.vue';
import AboutResuvoniaPage from '@/components/AboutResuvoniaPage.vue';
import DonatePage from '@/components/DonatePage.vue';
import BookPieceByPiece from '@/components/BookPieceByPiece.vue';
import SimplerBookPieceByPiece from '@/components/SimplerBookPieceByPiece.vue';

const routes = [
  {
    path: '/',
    name: 'books',
    component: BooksPage,
    alias: '/books',
  },
  {
    path: '/books/:bookId',
    name: 'book-details',
    component: BookDetails,
    props: true,
  },
  {
    // For users who have purchased the simpler version (read the paid content)
    path: '/simpler-book-details/:bookId',
    name: 'simpler-book-details',
    component: SimplerBookDetails,
    props: true,
  },
  {
    // For users who have not yet purchased the simpler version (handle purchase)
    path: '/simpler-book-purchase/:bookId',
    name: 'simpler-book-purchase',
    component: SimplerBookPurchase,
    props: true,
  },
  {
    path: '/choose-action/:topicType/:topicId/:topicName',
    name: 'choose-action',
    component: ChooseActionPage,
    props: true,
  },
  {
    path: '/simpler-choose-action/:topicType/:topicId/:topicName',
    name: 'simpler-choose-action',
    component: SimplerChooseActionPage,
    props: true,
  },
  {
    path: '/about-resuvonia',
    name: 'about-resuvonia',
    component: AboutResuvoniaPage,
  },
  {
    path: '/donate',
    name: 'donate',
    component: DonatePage,
  },
  {
    path: '/book-piece-by-piece/:bookId',
    name: 'book-piece-by-piece',
    component: BookPieceByPiece,
    props: true,
  },
  {
    path: '/simpler-book-piece-by-piece/:bookId',
    name: 'simpler-book-piece-by-piece',
    component: SimplerBookPieceByPiece,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
