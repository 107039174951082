<template>
  <div class="container">
    <Header
      :showBackButton="true"
      :backText="`Back to ${computedBackText}`"
      @back="goBack"
    />

    <main class="content" role="main">
      <h1>Choose an Action for {{ topicType }}: {{ topicName }}</h1>

      <!-- Options for books -->
      <template v-if="topicType === 'book'">
        <!-- "Read Book In Whole" Option -->
        <div
          class="action-button"
          role="button"
          tabindex="0"
          aria-label="Read Book In Whole"
          @click="readContent"
          @keydown.enter="readContent"
        >
          Read Book in Whole
        </div>

        <!-- "Read Book Piece by Piece" Option -->
        <div
          class="action-button"
          role="button"
          tabindex="0"
          aria-label="Read Book Piece by Piece"
          @click="readPieceByPiece"
          @keydown.enter="readPieceByPiece"
        >
          Read Book Piece by Piece
        </div>

        <!-- "Read Simpler Version" Option -->
        <div
          class="action-button"
          role="button"
          tabindex="0"
          aria-label="Read Simpler Version"
          @click="readSimplerVersion"
          @keydown.enter="readSimplerVersion"
        >
          Read Simpler Version
        </div>
      </template>

      <!-- Options for non-book topics -->
      <template v-else>
        <!-- "Read" Option -->
        <div
          class="action-button"
          role="button"
          tabindex="0"
          :aria-label="`Read ${topicType}`"
          @click="readContent"
          @keydown.enter="readContent"
        >
          Read {{ topicType }}
        </div>

        <!-- "Take a Test" Option -->
        <div
          class="action-button"
          role="button"
          tabindex="0"
          :aria-label="`Take a Test about ${topicName}`"
          @click="takeTest"
          @keydown.enter="takeTest"
        >
          Take a Test about {{ topicName }}
        </div>
      </template>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "ChooseActionPage",
  components: { Header },
  props: {
    topicType: {
      type: String,
      required: true,
    },
    topicId: {
      type: [Number, String],
      required: true,
    },
    topicName: {
      type: String,
      required: true,
    },
  },
  computed: {
    computedBackText() {
      switch (this.topicType) {
        case "news":
          return "Get More Resources";
        case "book":
          return "Books";
        case "article":
          return "Articles";
        case "research":
          return "Research";
        default:
          return "Resources";
      }
    },
  },
  methods: {
    goBack() {
      if (this.topicType === "news") {
        this.$router.push({ name: "resources" });
      } else if (this.topicType === "book") {
        this.$router.push("/books");
      } else if (this.topicType === "article") {
        this.$router.push("/articles");
      } else if (this.topicType === "research") {
        this.$router.push("/research");
      } else {
        console.warn("Unknown topic type:", this.topicType);
        this.$router.push({ name: "resources" });
      }
    },
    readContent() {
      if (this.topicType === "news") {
        if (!this.topicId || this.topicId <= 0) {
          console.warn("Invalid newsId; falling back to 1.");
          this.$router.push({
            name: "news-page",
            params: { newsId: 1 },
          });
          return;
        }
        this.$router.push({
          name: "news-page",
          params: { newsId: this.topicId },
        });
      } else if (this.topicType === "research") {
        this.$router.push({
          name: "research-details",
          params: { studyId: this.topicId },
        });
      } else if (this.topicType === "book") {
        this.$router.push({
          name: "book-details",
          params: { bookId: this.topicId },
        });
      } else if (this.topicType === "article") {
        this.$router.push({
          name: "article-details",
          params: { articleId: this.topicId },
        });
      } else {
        console.error(`Unknown topic type: ${this.topicType}`);
      }
    },
    readPieceByPiece() {
      if (this.topicType === "book") {
        this.$router.push({
          name: "book-piece-by-piece",
          params: { bookId: this.topicId },
        });
      } else {
        console.error(`Piece-by-piece reading is only available for books.`);
      }
    },
    readSimplerVersion() {
      if (this.topicType === "book") {
        const id = parseInt(this.topicId, 10);
        if (isNaN(id)) {
          console.error("Invalid book id");
          return;
        }
        // If the simpler version has been purchased, route to the simpler choose action page.
        // Otherwise, route to the simpler book purchase page.
        if (localStorage.getItem(`simplerBookContent_${id}`)) {
          this.$router.push({
            name: "simpler-choose-action",
            params: {
              topicType: "book",
              topicId: id,
              topicName: this.topicName,
            },
          });
        } else {
          this.$router.push({
            name: "simpler-book-purchase",
            params: { bookId: id },
          });
        }
      } else {
        console.error(`No simpler version available for ${this.topicType}`);
      }
    },
    takeTest() {
      this.$router.push({
        name: "test-page",
        params: {
          topicType: this.topicType,
          topicId: this.topicId,
          topicName: this.topicName,
        },
      });
    },
  },
};
</script>

<style scoped>
/* Container and layout */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Helvetica Neue", Arial, sans-serif;
  background: #fafafa;
  margin: 0;
}

/* Content area */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  flex: 1;
  background: linear-gradient(
    135deg,
    rgba(249, 249, 249, 0.8),
    rgba(243, 243, 243, 0.8)
  );
  backdrop-filter: blur(4px);
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Page title */
.content h1 {
  font-size: 2rem;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

/* Action button */
.action-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 18px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 260px;
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 500;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  outline: none;
}

/* Hover and focus states */
.action-button:hover,
.action-button:focus {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ac4dac, #ff4747);
}
</style>
