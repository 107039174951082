<template>
  <div class="book-details-page">
    <Header
      :showBackButton="true"
      :backText="headerBackText"
      @back="handleBack"
    />

    <!-- Display error message if any -->
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

    <!-- Loading state while fetching book details -->
    <div v-else-if="isLoading" class="loading-container">
      <p class="loading-message">Loading book details…</p>
    </div>

    <!-- Fallback if no book is found -->
    <div v-else-if="!book" class="container">
      <div class="content">
        <h1 class="centered-title">No Book Found</h1>
        <p class="centered-content">
          The simpler version is not available. Please contact
          <strong>resuvonia@gmail.com</strong> for assistance.
        </p>
      </div>
    </div>

    <!-- Main content: show paid content or link to purchase -->
    <div v-else class="container" ref="container">
      <!-- If paid, display the book text -->
      <div v-if="paid" class="content">
        <h1 class="centered-title">{{ book.title }}</h1>
        <pre class="centered-content">{{ bookContent }}</pre>
        <p class="backup-note">
          Please remember to save a private backup copy of this text.
        </p>
      </div>

      <!-- If not paid, prompt to purchase -->
      <div v-else class="content">
        <h1 class="centered-title">{{ book.title }}</h1>
        <p class="book-description">
          You haven't purchased this simpler version yet.
        </p>
        <router-link :to="{ name: 'simpler-book-purchase', params: { bookId: book.id } }">
          <button class="purchase-button">Purchase this Book</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import axios from "@/axiosConfig.js";

export default {
  name: "SimplerBookDetails",
  components: { Header },
  data() {
    return {
      book: { id: null, title: "", txt: "", price: 0 },
      bookContent: "",
      errorMessage: "",
      paid: false,
      sessionId: null,
      isLoading: true,
    };
  },
  computed: {
    headerBackText() {
      return this.book && this.book.id ? "Back to Choose Action" : "Back to Books";
    },
  },
  async created() {
    const bookId = this.$route.params.bookId;
    try {
      const response = await axios.get(`/api/stripe/book-details/${bookId}`);
      this.book = response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        this.book = null;
      } else {
        this.errorMessage = "Failed to load book details. Please try again.";
      }
    } finally {
      this.isLoading = false;
    }

    // Check if the paid content is cached
    if (this.book && localStorage.getItem(`simplerBookContent_${this.book.id}`)) {
      this.bookContent = localStorage.getItem(`simplerBookContent_${this.book.id}`);
      this.paid = true;
      console.log("Loaded cached book content for book ID", this.book.id);
    }

    // If a Stripe session_id is present, verify payment
    if (this.$route.query.session_id) {
      this.sessionId = this.$route.query.session_id;
      await this.verifySession(this.sessionId);
    }

    if (this.paid && !this.bookContent) {
      await this.fetchPaidContent(this.sessionId);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.restoreScrollPosition();
    });
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeUnmount() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    next();
  },
  watch: {
    bookContent(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.restoreScrollPosition();
          console.log("Restored scroll position after bookContent update.");
        });
      }
    },
  },
  methods: {
    async verifySession(sessionId) {
      try {
        const resp = await axios.get("/api/stripe/verify-session", {
          params: { session_id: sessionId },
        });
        if (resp.data && resp.data.paid) {
          this.paid = true;
          console.log("Payment verified for session", sessionId);
          await this.fetchPaidContent(sessionId);
        }
      } catch (err) {
        this.errorMessage = "Could not verify payment. Please try again.";
      }
    },
    async fetchPaidContent(sessionId) {
      try {
        const bookId = this.$route.params.bookId;
        const url = `/api/stripe/download-content/${bookId}?session_id=${sessionId}`;
        const resp = await axios.get(url, { responseType: "text" });
        this.bookContent = resp.data;
        localStorage.setItem(`simplerBookContent_${bookId}`, resp.data);
        console.log("Fetched and cached paid content for book ID", bookId);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.errorMessage =
            error.response.data.error || "This link is no longer valid.";
          this.paid = false;
          this.bookContent = "";
        } else {
          this.errorMessage = "Could not load your paid content. Contact support.";
        }
      }
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        const pos = this.$refs.container.scrollTop;
        localStorage.setItem(`scrollPosition_Book_${this.book.id}`, pos);
        console.log(`Saved scroll position for book ${this.book.id}:`, pos);
      }
    },
    restoreScrollPosition() {
      const savedPosition = localStorage.getItem(`scrollPosition_Book_${this.book.id}`);
      this.$nextTick(() => {
        if (this.$refs.container && savedPosition) {
          this.$refs.container.scrollTop = parseInt(savedPosition, 10);
          console.log(`Restored scroll position for book ${this.book.id}:`, savedPosition);
        }
      });
    },
    handleBack() {
      if (!this.book || !this.book.id) {
        this.$router.push({ name: "books" });
      } else {
        this.$router.push({
          name: "simpler-choose-action",
          params: {
            topicType: "book",
            topicId: this.book.id,
            topicName: this.book.title,
          },
        });
      }
    },
  },
};
</script>

<!-- Global Scrollbar Styles (Optional) -->
<style>
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>

<!-- Scoped Styles for SimplerBookDetails.vue -->
<style scoped>
.book-details-page {
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Updated container: prevents horizontal movement */
.container {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden; /* Disable horizontal scrolling */
  box-sizing: border-box;
  background: linear-gradient(135deg, #ffffff, #f3f3f3);
  scroll-behavior: smooth;
  padding: 20px;
  padding-bottom: 120px;
  touch-action: pan-y;
  overscroll-behavior: contain;
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  min-height: 100%;
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  line-height: 1.2;
  font-family: "Georgia", serif;
  color: #333;
  font-weight: 600;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
  line-height: 1.6;
  font-family: "Georgia", serif;
  color: #444;
}

.book-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #444;
  max-width: 700px;
  text-align: center;
}

.purchase-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 14px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
}

.purchase-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ac4dac, #ff4747);
}

.error-message {
  color: #d9534f;
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}

.loading-message {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
}

.backup-note {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #777;
  text-align: center;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .container {
    padding: 10px;
    padding-bottom: 100px;
    overflow-x: hidden;
    touch-action: pan-y;
    overscroll-behavior: contain;
  }
  .content {
    padding: 15px;
  }
  .centered-title {
    font-size: 1.4rem;
  }
  .centered-content {
    font-size: 1rem;
  }
}
</style>
