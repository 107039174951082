<template>
  <div class="about-page">
    <!-- Header with back button -->
    <Header
      :showBackButton="true"
      backText="Previous Page"
      @back="goBackToPreviousPage"
    />

    <!-- Main content container (scrollable) -->
    <main class="container" ref="container" role="main">
      <div class="content">
        <!-- Founder Section -->
        <h1 class="title">Theodor N. Engøy</h1>
        <figure class="founder-figure">
          <img
            src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/theodor-engoy.png"
            alt="Theodor N. Engøy"
            class="founder-image"
          />
          <figcaption>Founder of Resuvonia</figcaption>
        </figure>

        <!-- Contact Section -->
        <section class="contact-section">
          <h2>Contact Resuvonia</h2>
          <a
            href="mailto:resuvonia@gmail.com"
            class="contact-logo"
            aria-label="Email Resuvonia"
          >
            <img
              src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/gmail-logo.png"
              alt="Gmail logo"
              class="social-logo"
            />
          </a>
        </section>

        <!-- Social Media Section -->
        <section class="social-media-section">
          <h2>Resuvonia’s Social Media</h2>
          <div class="social-media-links">
            <a
              href="https://www.youtube.com/@Resuvonia"
              target="_blank"
              rel="noopener"
              aria-label="Resuvonia on YouTube"
            >
              <img
                src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/youtube-logo.png"
                alt="YouTube logo"
                class="social-logo"
              />
            </a>
            <a
              href="https://www.instagram.com/resuvonia/"
              target="_blank"
              rel="noopener"
              aria-label="Resuvonia on Instagram"
            >
              <img
                src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/instagram-logo.png"
                alt="Instagram logo"
                class="social-logo"
              />
            </a>
            <a
              href="https://x.com/Resuvonia"
              target="_blank"
              rel="noopener"
              aria-label="Resuvonia on X (formerly Twitter)"
            >
              <img
                src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/x-logo.png"
                alt="Twitter logo"
                class="social-logo"
              />
            </a>
          </div>
        </section>

        <!-- FAQ Section -->
        <section class="faq-section">
          <h2>Frequently Asked Questions</h2>

          <article class="faq-item">
            <p>
              <strong>1) What is Resuvonia.com?</strong><br />
              Resuvonia.com is a website where you can read free books in whole or piece by piece.
              You can also read simplified versions of every book for $0.3 each.
            </p>
          </article>

          <article class="faq-item">
            <p>
              <strong>2) Who founded Resuvonia, and when, and where?</strong><br />
              Resuvonia was founded by Theodor N. Engøy in September 2024 in Ås Kommune, Norway.
              Mr. Engøy was born on March 3, 2005, in Ås, Norway, and began learning programming in June 2023.
            </p>
          </article>

          <article class="faq-item">
            <p>
              <strong>3) How is Resuvonia.com made?</strong><br />
              Resuvonia.com is built using Vue.js, ChatGPT, Netlify, Heroku, GitHub, and AWS S3.
            </p>
          </article>

          <article class="faq-item">
            <p>
              <strong>4) Who owns Resuvonia.com?</strong><br />
              Resuvonia.com is a sole proprietorship owned by Theodor N. Engøy.
            </p>
          </article>

          <article class="faq-item">
            <p>
              <strong>5) Can I donate to Resuvonia.com?</strong><br />
              Yes, you can donate via Stripe if you’d like to support the platform.
            </p>
          </article>

          <article class="faq-item">
            <p>
              <strong>6) Why is it called “Resuvonia”?</strong><br />
              “Resuvonia” stands for resources, suggestions, and votes—key principles that Mr. Engøy
              believes are central to the universe. The “-nia” suffix sounds like a country (e.g., Romania)
              or a university or planet, which he thought sounded interesting. Also, “Resuvo” alone was unfortunately
              too expensive as a domain!
            </p>
          </article>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "AboutResuvoniaPage",
  components: { Header },
  methods: {
    goBackToPreviousPage() {
      this.$router.go(-1);
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem(
          "scrollPosition_AboutResuvoniaPage",
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const pos = localStorage.getItem("scrollPosition_AboutResuvoniaPage");
      this.$nextTick(() => {
        if (this.$refs.container && pos) {
          this.$refs.container.scrollTop = parseInt(pos, 10);
        }
      });
    },
  },
  mounted() {
    // Restore scroll on mount
    this.restoreScrollPosition();
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
};
</script>

<!-- Global Scrollbar Styles (Optional) -->
<style>
/* Customize scrollbars in WebKit-based browsers */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>

<!-- Scoped Styles for AboutResuvoniaPage.vue -->
<style scoped>
.about-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
}

.container {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #f9f9f9;
  padding: 20px;
  padding-bottom: 120px;
  touch-action: pan-y;
  overscroll-behavior: contain;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

/* Founder Section */
.title {
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
  font-family: "Georgia", serif;
}

.founder-figure {
  margin: 0 auto 20px auto;
}

.founder-image {
  max-width: 250px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.founder-figure figcaption {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #444;
}

/* Contact & Social Media Sections */
.contact-section,
.social-media-section {
  margin: 20px 0;
}

.contact-section h2,
.social-media-section h2 {
  font-size: 1.8rem;
  color: #444;
  margin-bottom: 10px;
}

.social-media-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-logo {
  width: 50px;
  transition: transform 0.3s ease;
}

.social-logo:hover,
.social-logo:focus {
  transform: translateY(-3px);
  outline: 2px dashed #333;
  border-radius: 4px;
}

.contact-logo {
  display: inline-block;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.contact-logo:hover,
.contact-logo:focus {
  transform: translateY(-3px);
  outline: 2px dashed #333;
  border-radius: 4px;
}

/* FAQ Section */
.faq-section {
  margin: 30px auto 0 auto;
  width: 100%;
  max-width: 900px;
  text-align: center;
}

.faq-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-family: "Georgia", serif;
}

.faq-item {
  margin: 0 auto 1.5rem auto;
  max-width: 800px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /* Center the box within the FAQ section */
  text-align: center;
}

.faq-item p {
  margin: 0;
  line-height: 1.6;
  color: #555;
}

.faq-item strong {
  color: #333;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .container {
    padding: 10px;
    padding-bottom: 100px;
    overflow-x: hidden;
    touch-action: pan-y;
    overscroll-behavior: contain;
  }
  .content {
    padding: 10px;
  }
  .title {
    font-size: 2rem;
  }
  .founder-image {
    max-width: 200px;
  }
  .contact-section h2,
  .social-media-section h2,
  .faq-section h2 {
    font-size: 1.6rem;
  }
  .faq-item {
    padding: 10px;
  }
}
</style>
