<template>
  <div class="book-purchase-page">
    <!-- Header with fixed back button text -->
    <Header
      :showBackButton="true"
      backText="Back to Books"
      @back="handleBack"
    />

    <div class="container">
      <!-- If a book is found, display the purchase form -->
      <div class="content" v-if="book && book.id">
        <h1 class="centered-title">{{ book.title }}</h1>
        <p class="book-description">
          This simpler version is available exclusively.
          Purchase it for <strong>\${{ book.price }}</strong> to gain access.
          Once purchased, the text will be saved in your browser’s local storage.
        </p>

        <form @submit.prevent="purchaseBook" class="purchase-form">
          <label for="email">Email/Gmail (Required):</label>
          <input
            v-model="email"
            type="email"
            id="email"
            placeholder="Enter your email"
            required
          />
          <button type="submit" class="purchase-button">
            Buy &amp; Access on Resuvonia
          </button>
        </form>
      </div>

      <!-- Fallback when no book is found -->
      <div class="content fallback" v-else>
        <h1 class="centered-title">Book not found</h1>
        <p class="centered-content">
          Please contact <strong>resuvonia@gmail.com</strong> for assistance.
        </p>
      </div>

      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import axios from "@/axiosConfig.js";
import simplerBooks from "@/simplerBooks.js";

export default {
  name: "SimplerBookPurchase",
  components: { Header },
  data() {
    return {
      // Default simpler book structure
      book: { id: null, title: "", price: 0, s3Keys: { txt: "" } },
      email: "",
      errorMessage: ""
    };
  },
  created() {
    const bookId = parseInt(this.$route.params.bookId, 10);
    const foundBook = simplerBooks.find((b) => b.id === bookId);
    if (foundBook) {
      this.book = foundBook;
    } else {
      this.errorMessage = "Book not found.";
    }
  },
  methods: {
    purchaseBook() {
      axios
        .post("/api/stripe/buy-book-checkout", {
          bookId: this.book.id,
          email: this.email,
          format: "txt" // Only TXT is offered.
        })
        .then((response) => {
          if (response.data && response.data.url) {
            // IMPORTANT: Ensure that your backend's Stripe checkout success_url
            // is configured to redirect to your SimplerChooseActionPage (not to SimplerBookDetails.vue).
            window.location.href = response.data.url;
          } else {
            this.errorMessage = "Failed to initiate checkout.";
          }
        })
        .catch(() => {
          this.errorMessage = "Error purchasing the book. Please try again.";
        });
    },
    handleBack() {
      // Always navigate back to the Books page
      this.$router.push({ name: "books" });
    }
  }
};
</script>

<style scoped>
.book-purchase-page {
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Scrollable container */
.container {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background: linear-gradient(135deg, #ffffff, #f3f3f3);
  padding: 20px;
  box-sizing: border-box;
}

/* Content box styling for both form and fallback */
.content {
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 600px;
}

/* Fallback styling tweaks */
.fallback {
  text-align: center;
}

/* Title styling */
.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-family: "Georgia", serif;
  color: #333;
  font-weight: 600;
}

/* Book description styling */
.book-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #444;
  line-height: 1.6;
  text-align: center;
}

/* Centered content text styling (used in fallback) */
.centered-content {
  font-size: 1.2rem;
  color: #444;
  text-align: center;
  margin: 10px 0;
}

/* Purchase form styling */
.purchase-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.purchase-form label {
  align-self: flex-start;
  margin-bottom: 5px;
  color: #333;
}

.purchase-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Gradient Purchase Button */
.purchase-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 14px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
}

.purchase-button:hover {
  background: linear-gradient(45deg, #ac4dac, #ff4747);
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Error message styling */
.error-message {
  color: #d9534f;
  margin-top: 15px;
  font-weight: 500;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .centered-title {
    font-size: 1.4rem;
  }
  .purchase-form input {
    font-size: 14px;
  }
}
</style>
