<template>
  <div class="header" ref="headerRoot">
    <div class="col-left">
      <!-- Back button -->
      <button 
        v-if="showBackButton" 
        @click="emitBack" 
        class="nav-button"
      >
        {{ backText }}
      </button>
    </div>

    <div class="col-center">
      <router-link to="/">
        <img
          src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/profile-picture.jpg"
          class="logo"
          alt="Logo"
        />
      </router-link>
    </div>

    <div class="col-right">
      <router-link to="/donate">
        <button class="nav-button">Donate</button>
      </router-link>
      <router-link to="/about-resuvonia">
        <button class="nav-button">About Resuvonia</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    showBackButton: { type: Boolean, default: false },
    backText: { type: String, default: "Back" },
  },
  methods: {
    emitBack() {
      this.$emit("back");
    },
    handleClickOutside(event) {
      // Optional: if you have a dropdown or other elements that close on outside click,
      // you can handle it here.
      if (!this.$refs.headerRoot.contains(event.target)) {
        // Close dropdown if applicable...
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;

  /* Three-column grid for left/center/right alignment */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  /* Dark gradient background to stand out */
  background: linear-gradient(135deg, #444444, #2c2c2c);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  z-index: 1000;
}

/* Column definitions */
.col-left,
.col-center,
.col-right {
  display: flex;
  align-items: center;
}
.col-left {
  padding-left: 20px;
}
.col-center {
  justify-content: center;
}
.col-right {
  justify-content: flex-end;
  padding-right: 20px;
}

/* Logo styling */
.logo {
  height: 50px;
  cursor: pointer;
  transition: transform 0.3s;
}
.logo:hover {
  transform: scale(1.05);
}

/* Navigation buttons */
.nav-button {
  background-color: #2c2c2c;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 10px;

  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  outline: none;
}

.nav-button:hover,
.nav-button:focus {
  background-color: #444444;
  transform: translateY(-2px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2);
}
</style>
